import { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Stack,
  Box,
  Button,
  InputAdornment,
  Grid,
  Card,
  CardHeader,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  DialogContent,
  Typography,
  useTheme,
  IconButton,
  MenuItem,
  Link,
} from '@mui/material';
import Scrollbar from '../../../../components/Scrollbar';
import { useParams, useLocation, Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from '../../../../redux/store';
import useAuth from '../../../../hooks/useAuth';
import useSettings from '../../../../hooks/useSettings';
import useLocales from '../../../../hooks/useLocales';
import Page from '../../../../components/Page';
import PageError from '../../../../components/PageError';
import PageVenueNotFound from '../../../../components/PageVenueNotFound';
import LoadingScreen from '../../../../components/LoadingScreen';
import { getPendingVenues } from '../VenuesSlice';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import InputStyle from 'src/components/InputStyle';
import Iconify from 'src/components/Iconify';
import MenuPopover from 'src/components/MenuPopover';


const useOverviewBranchSummaryTable = ({ pendingVenues }) => {
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - pendingVenues.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return { page, rowsPerPage, emptyRows, handleChangePage };
};


export default function PendingVenueList() {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { translate: t, Translate } = useLocales();
  const { themeStretch } = useSettings();
  const { pendingVenues, isLoading, error } = useSelector((state) => state.venueOnboardEdit);

  const { page, rowsPerPage, emptyRows, handleChangePage } = useOverviewBranchSummaryTable({
    pendingVenues,
  });
  const [searchSpace, setSearchSpace] = useState("");

  const linkToNewBranch = `${PATH_DASHBOARD.organisation.add}`;

  useEffect(() => {
    const fetchUsers = async () => {
      dispatch(getPendingVenues());
    };
    fetchUsers();
  }, [dispatch]);

  const filteredVenues = useMemo(
    () => searchSpace.length > 3 ? pendingVenues.filter(venue => pendingVenues?.name?.toLowerCase().includes(searchSpace?.toLowerCase())) : pendingVenues,
    [pendingVenues, searchSpace]
  );
  return (
    <Page title={t('Users')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h3" component="h1" paragraph>
              <Translate i18nKey="Pending Venues" /> {user?.displayName}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {isLoading ? (
              <LoadingScreen />
            ) : error ? (
              error === "Branch allocation is missing" ? (
                <PageVenueNotFound error={error} />
              ) : (
                <PageError error={error} />
              )
            ) : (
              <>
                <InputStyle
                  size="small"
                  stretchStart={240}
                  value={searchSpace}
                  onChange={(event) => setSearchSpace(event.target.value)}
                  placeholder={t('Search for pendingVenues')}
                  sx={{ mb: 4 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid item xs={12}>
                  <Card>
                    {/* <CardHeader title={t('global.commissionEarned')} subheader={t('overview.thisMonth')} sx={{ mb: 3 }} /> */}
                    <Scrollbar>
                      <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>
                                <Translate i18nKey="Id" />
                              </TableCell>
                              <TableCell>
                                <Translate i18nKey="Name" />
                              </TableCell>
                              <TableCell>
                                <Translate i18nKey="Status" />
                              </TableCell>
                              <TableCell>
                                <Translate i18nKey="More" />
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {pendingVenues &&
                              pendingVenues
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item) => <BranchItem key={item.id} venue={item} />)}
                            {emptyRows > 0 && (
                              <TableRow style={{ height: 54 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                      <TablePagination
                        component="div"
                        page={page}
                        rowsPerPage={rowsPerPage}
                        count={pendingVenues.length}
                        onPageChange={handleChangePage}
                        rowsPerPageOptions={[]}
                      />
                    </Scrollbar>
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
BranchItem.propTypes = {
  venue: PropTypes.object,
};

function BranchItem({ venue }) {
  const [open, setOpen] = useState(null);
  const theme = useTheme();
  const { Translate } = useLocales();
  const linkToDetail = `${PATH_DASHBOARD.organisation.root}/${venue?.org_id || 'W2CBE7LJYR6'}/venues/${venue?.venue_id}/edit?tab=settings`


  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };


  return (
    <TableRow>
      <TableCell>{venue.venue_id}</TableCell>
      <TableCell>{venue.venue_name}</TableCell>
      <TableCell>{venue.status}</TableCell>
      <TableCell>
        {/* <IconButton component={RouterLink} to={linkToDetail} onClick={() => { setOpen(true) }}> */}
        {/* <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} /> */}
        {/* <Translate component={RouterLink} to={linkToDetail} i18nKey='Edit' /> */}
        {/* </IconButton> */}
        <Link component={RouterLink} variant="body1" to={linkToDetail} sx={{ fontWeight: 'fontWeightMedium', pl: 1 }}>
          <Translate i18nKey='Edit' />
        </Link>
        <MenuPopover
          open={Boolean(open)}
          anchorEl={open}
          onClose={() => { setOpen(!open) }}
          anchorOrigin={{ vertical: 'top', horizontal: theme.direction === 'rtl' ? 'right' : 'left' }}
          transformOrigin={{ vertical: 'top', horizontal: theme.direction === 'rtl' ? 'left' : 'right' }}
          arrow="right-top"
          sx={{
            mt: -1,
            width: 160,
            '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
          }}
        >
          <MenuItem>
            <Iconify icon={'fluent:text-bullet-list-square-search-20-regular'} sx={{ ...ICON }} />
            <Translate i18nKey='Link Venues' />
          </MenuItem>
        </MenuPopover>
      </TableCell>
    </TableRow>
  );
}
