import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../../utils/axios';
import { URL_ORGANIZATION, URL_PLACES } from '../../../utils/restApiUrls';
//
import { dispatch } from '../../../redux/store';
import { enqueueSnackbar } from 'notistack';


const USERS_DETAILS_FETCH_ERROR = 'orginization.error.fetchUsers';
const CREATE_USER_SUCCESS = 'orginization.users.createUser';

const initialState = {
  isLoading: true,
  isLinkingLoading: true,
  error: null,
  users: [],
  organisationsList: [],
  Orginization: null,
  error: null,
  alertError: null,
  alertSuccess: null,
}

const slice = createSlice({
  name: 'orginization',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startLinkingLoading(state) {
      state.isLinkingLoading = true;
    },
    stopLinkingLoading(state) {
      state.isLinkingLoading = false;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // START LOADING
    usersFetched(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // START LOADING
    organisationsListSuccess(state, action) {
      state.isLoading = false;
      state.organisationsList = action.payload;
    },
    // START LOADING
    OrginisationFetched(state, action) {
      state.isLoading = false;
      state.Orginization = action.payload;
    },
    

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Display Alert Success
    setAlertSuccess(state, action) {
      const message = action.payload;
      state.alertSuccess = message;
    },

    // Display Alert Error
    setAlertError(state, action) {
      const message = action.payload;
      state.alertError = message;
    },

    // Reset Alert Success
    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    // Reset Alert Error
    resetAlertError(state) {
      state.alertError = null;
    },

  },
});

export default slice.reducer;

// Actions
export const { resetAlertSuccess, resetAlertError } = slice.actions;

// ----------------------------------------------------------------------

export function getUsers(org_id) {
  console.log('getting branch details');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_ORGANIZATION.list.replace(":org_id", org_id));
      const { status, success, data } = response.data;
      console.log(response.data, "response.data");
      if (success) {
        // eslint-disable-next-line camelcase
        dispatch(slice.actions.usersFetched(data))
        // window.localStorage.setItem('lwt_ut', user_type);
      }
    } catch (error) {
      dispatch(slice.actions.stopLoading());
    }
  }
}



// ----------------------------------------------------------------------

export function getOrganisationsList() {
  console.log('getting branch details');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_ORGANIZATION.organisationsList);
      const { status, success, data } = response.data;
      console.log(response.data, "response.data");
      if (success) {
        // eslint-disable-next-line camelcase
        dispatch(slice.actions.organisationsListSuccess(data))
        // window.localStorage.setItem('lwt_ut', user_type);
      }
    } catch (error) {
      dispatch(slice.actions.stopLoading());
    }
  }
}


// ----------------------------------------------------------------------

export function getOrgDetail(org_id) {
  console.log('getting branch details');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_ORGANIZATION.profile.replace(":org_id", org_id));
      const { status, success, data } = response.data;
      console.log(response.data, "response.data");
      if (success) {
        // eslint-disable-next-line camelcase
        dispatch(slice.actions.OrginisationFetched(data))
        // window.localStorage.setItem('lwt_ut', user_type);
      }
    } catch (error) {
      dispatch(slice.actions.stopLoading());
    }
  }
}



// ----------------------------------------------------------------------

export function setOrgDetail(payload, org_id) {
  console.log('getting branch details');
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(URL_ORGANIZATION.profile.replace(":org_id", org_id),payload);
      const { status, success, data,message } = response.data;
      console.log(response.data, "response.data");
      if (success) {
        // eslint-disable-next-line camelcase
        console.log(message,"343");
        dispatch(slice.actions.setAlertSuccess(message));
        dispatch(slice.actions.OrginisationFetched(data));
      } else {
        dispatch(
          slice.actions.setAlertError(data.message)
        );
      }
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.setAlertError(error?.response?.data?.message));
    }
  }
}


// ----------------------------------------------------------------------

export function linkUsers(payload, userId) {
  console.log('getting branch details');
  return async () => {
    dispatch(slice.actions.startLinkingLoading());
    try {
      const response = await axios.post(URL_ORGANIZATION.link.replace(":user_id", userId), payload);
      const { status, success, data } = response.data;
      console.log(response.data, "response.data");
      if (success) {
        // dispatch(slice.actions.usersFetched(data))
        enqueueSnackbar('Venues linked successfully!', { variant: 'success' });
      }
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.stopLinkingLoading());
    }
  }
}