// @mui
import { Skeleton, Box, Grid } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonBookingDetails() {
  return (
    <Grid item xs={12}>
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center' }}>
        <Skeleton variant="circular" width={48} height={48} />
        <Box sx={{ ml: 2, width: '40%' }}>
          <Skeleton variant="text" height={20} />
        </Box>
        <Box sx={{ flexGrow: 1 }}/>
      </Box>
      <Skeleton variant="rectangular" width="90%" sx={{ height: 300, borderRadius: 2, mt:3, display: 'block' }} />
    </Grid>
  );
}
