// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
// hooks
import useAuth from '../../../hooks/useAuth';
// config
import { ROLE_BASED_DISPLAY } from '../../../config';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  settings: getIcon('ic_settings'),
};


const conditionalConfig = {
  calendar: [{ title: 'calendar', path: PATH_DASHBOARD.dashboard.calendar, icon: ICONS.calendar }],
  sales: {
    BOTH: [{
      title: 'Sales',
      path: PATH_DASHBOARD.dashboard.sales.root,
      icon: ICONS.ecommerce,
      children: [
        { title: 'bookings', path: PATH_DASHBOARD.dashboard.sales.bookings },
        { title: 'coworking', path: PATH_DASHBOARD.dashboard.sales.coworking },
      ],
    }],
    BOOK: [{ title: 'Sales', path: PATH_DASHBOARD.dashboard.sales.bookings, icon: ICONS.ecommerce }],
    WORK: [{ title: 'Sales', path: PATH_DASHBOARD.dashboard.sales.coworking, icon: ICONS.ecommerce }],
  },
  customers: {
    BOTH: [{
      title: 'Customers',
      path: PATH_DASHBOARD.dashboard.customers.root,
      icon: ICONS.user,
      children: [
        { title: 'bookings', path: PATH_DASHBOARD.dashboard.customers.bookings },
        { title: 'coworking', path: PATH_DASHBOARD.dashboard.customers.coworking },
      ],
    }],
    BOOK: [{ title: 'Customers', path: PATH_DASHBOARD.dashboard.customers.bookings, icon: ICONS.user }],
    WORK: [{ title: 'Customers', path: PATH_DASHBOARD.dashboard.customers.coworking, icon: ICONS.user }]
  }
};


const navConfig = () => [
  // DASHBOARD
  // ----------------------------------------------------------------------
  {
    subheader: ' ',
    items: [
      { title: 'overview', path: PATH_DASHBOARD.dashboard.overview, icon: ICONS.dashboard },
      { title: 'orginization', path: PATH_DASHBOARD.organisation.list, icon: ICONS.banking },
      { title: 'venues', path: PATH_DASHBOARD.venues.list, icon: ICONS.banking },
      ...(GetUserRoleBasedRouteConfig()),
      
      { title: 'Settings', path: PATH_DASHBOARD.settings, icon: ICONS.settings },
    ],
  },
];

export default navConfig;

function GetUserRoleBasedRouteConfig() {
  const userType = window.localStorage.getItem('lwt_user');
  const systemUser = JSON.parse(userType)?.system_user;
  console.log('GetUserRoleBasedRouteConfig', systemUser, ROLE_BASED_DISPLAY.BOTH.includes(systemUser));
  /*
   *
    USER_SUPER_ADMIN = 1
    USER_ADMIN = 2
    USER_MEMBER = 3
    USER_BRANCH_MANAGER = 12
    USER_LM_VENUE_MANAGER = 10
    USER_COUNTRY_MANAGER = 11
    USER_COWORK_MANAGER = 4
   */

  if(ROLE_BASED_DISPLAY.BOTH.includes(systemUser)) { // USER_SUPER_ADMIN , USER_ADMIN , USER_BRANCH_MANAGER
    return [
      { title: 'pending', path: PATH_DASHBOARD.venues.pending, icon: ICONS.banking },
      // ...(conditionalConfig.calendar),
      // ...(conditionalConfig.sales.BOTH),
      // ...(conditionalConfig.customers.BOTH)
    ]
  }
  if(ROLE_BASED_DISPLAY.WORK.includes(userType)) { // USER_BRANCH_MANAGER , USER_COWORK_MANAGER
    return [
      ...(conditionalConfig.sales.WORK),
      ...(conditionalConfig.customers.WORK)
    ]
  }
  if(ROLE_BASED_DISPLAY.BOOK.includes(userType)) { // USER_BRANCH_MANAGER, USER_LM_VENUE_MANAGER
    return [
      ...(conditionalConfig.calendar),
      ...(conditionalConfig.sales.BOOK),
      ...(conditionalConfig.customers.BOOK)
    ]
  }

  return [];

}
