import PropTypes from 'prop-types';
import { m } from 'framer-motion';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Box, Link, Container, Typography, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LetsworkLogo from '../../components/LetsworkLogo';
import { MotionContainer, varFade } from '../../components/animate';
// routes
import useLocales from '../../hooks/useLocales';
import useAuth from '../../hooks/useAuth';
import { PATH_DASHBOARD } from '../../routes/paths';
import { LoginForm, SignUpForm, OTP } from '.';

// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  position: 'relative',
  // backgroundColor: theme.palette.grey[400],
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
  },
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
  zIndex: 10,
  maxWidth: 520,
  margin: 'auto',
  textAlign: 'left',
  position: 'relative',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    margin: 'unset',
    textAlign: 'left',
  },
}));


const HeroImgStyle = styled(m.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  width: '100%',
  margin: 'auto',
  position: 'absolute',
  display: 'none',
  [theme.breakpoints.up('lg')]: {
    right: '8%',
    width: 'auto',
    height: '48vh',
    display: 'block',
  },
}));

const CornerCutShapeStyle = styled(Box)(() => ({
  '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
  'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
  width: 'fit-content',
}));



// ----------------------------------------------------------------------
SignUpHero.propTypes = {
  defaultScreen: PropTypes.string,
};
// ----------------------------------------------------------------------

export default function SignUpHero({ defaultScreen }) {
  const [screen, setScreen] = useState(defaultScreen);
  const { user } = useAuth();
  const { Translate } = useLocales();
  const emailOrMobile = user?.email || `+${user?.mobile}`;
  const userDisplayName = user?.first_name || '';
  ;

  const changeScreen = (screen) => {
    setScreen(screen);
  };

  return (
    <MotionContainer>
      <RootStyle>
        <HeroImgStyle
          alt="hero"
          src="/assets/hero.webp"
          variants={varFade().inUp}
        />
        <Container>
          <ContentStyle>
            {/* <m.div variants={varFade().inRight}> */}
            <m.div variants={varFade().inRight}>
              <LetsworkLogo sx={{ color: '#0B050E' }} />
            </m.div>
            <Box sx={{ flexGrow: 1, /* px: 3, py: 4, backgroundColor: 'primary.darker', */ borderRadius: 1 }}>
              <m.div variants={varFade().inRight}>
                {(screen === 'login') && (
                  <Typography variant="h3" sx={{ pb: 4 }} >
                    {/* <Translate i18nKey='login.title' /> */}
                    Login
                  </Typography>
                )}
                {(screen === 'finishSignUp') && (
                  <Typography variant="h3" sx={{ pb: 4 }} >
                    {/* <Translate i18nKey='login.title' /> */}
                    Sign up
                  </Typography>
                )}
                {screen === 'OTP' && (
                  <Stack spacing={1} sx={{ pb: 4 }}>
                    <Typography variant="h4" align="start" >
                      Email verification code
                    </Typography>
                    <Typography variant="subtitle" align="start" >
                      {/* Enter the OTP sent to {user.email || `+${user.mobile}`} */}
                      <Translate i18nKey='login.otpTitle' emailOrMobile={emailOrMobile} >
                        Enter the OTP sent to {{ emailOrMobile }}
                      </Translate>
                    </Typography>
                  </Stack>
                )}
                {(screen === 'welcome') && (
                  <Stack spacing={1} sx={{ pb: 4 }}>
                    <Typography variant="h3" sx={{ pb: 2 }} >
                      {/* <Translate i18nKey='login.title' /> */}
                      Welcome onboard{userDisplayName ? <><br />{userDisplayName}</> : ''}!
                    </Typography>

                    <Typography variant="subtitle" align="start" sx={{ pb: 4 }}>
                      We’ll just be collecting some details to get you started.
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 4 }}>
                      <Link component={RouterLink} variant="body1" to={PATH_DASHBOARD.onboarding.replace(":org_id",user?.organisation_user_details?.
        organisation?.id)} sx={{ fontWeight: 'fontWeightMedium' }}>
                        <CornerCutShapeStyle sx={{ mb: 2, position: 'relative' }}>
                          <LoadingButton
                            size="large"
                            variant="contained"
                          >
                            Get Started
                          </LoadingButton>
                        </CornerCutShapeStyle>
                      </Link>
                      <Link component={RouterLink} variant="body1" to={PATH_DASHBOARD.dashboard.overview} sx={{ fontWeight: 'fontWeightMedium', pl: 1 }}>
                        {/* {translate('login.forgotPassword')} */}
                        Skip
                      </Link>
                    </Stack>
                  </Stack>
                )}
              </m.div>
              {screen === 'login' && (
                <m.div variants={varFade().inRight}>
                  <LoginForm changeScreen={changeScreen} />
                </m.div>
              )}
              {screen === 'finishSignUp' && (
                <m.div variants={varFade().inRight}>
                  <SignUpForm changeScreen={changeScreen} />
                </m.div>
              )}
              {screen === 'OTP' && (
                <m.div key={defaultScreen} variants={varFade().inRight}>
                  <OTP changeScreen={changeScreen} defaultScreen={defaultScreen} />
                </m.div>
              )}

              {(screen === 'finishSignUp') && (
                <m.div variants={varFade().inRight}>
                  <Typography sx={{ my: 2, color: '#767676' }}>
                    <Translate i18nKey='login.termsInfo'>
                      By continuing, you agree to Letswork’s
                      <Link href="https://www.letswork.io/terms-conditions">Terms of Service</Link> and
                      <Link href="https://www.letswork.io/privacy-policy">Privacy Policy</Link>.
                    </Translate>
                  </Typography>
                </m.div>
              )}

            </Box>
          </ContentStyle>
        </Container>
      </RootStyle>
      <Box sx={{ height: { md: '100vh' } }} />
    </MotionContainer>
  );
}
