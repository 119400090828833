import { SignUpHero } from './pages/auth';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './contexts/TokenContext';
import NotistackProvider from './components/NotistackProvider';
import Router from './routes';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import ThemeProvider from './theme';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';


function App() {
  return (
    <div>
      <ThemeProvider>
        <ThemeColorPresets>
          <ThemeLocalization>
            <AuthProvider>
              <BrowserRouter>
                <NotistackProvider>
                  <MotionLazyContainer>
                    <Router />
                  </MotionLazyContainer>
                </NotistackProvider>
              </BrowserRouter>
            </AuthProvider>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
    </div>
  );
}

export default App;
