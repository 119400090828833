import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import overviewReducer from '../pages/dashboard/overview/overview';
import orginizationUsersReducer from '../pages/dashboard/organisation/OrganisationSlice';
import venueOnboardEditReducer from '../pages/dashboard/venues/VenuesSlice';
import spaceOnboardEditReducer from '../pages/dashboard/spaces/SpacesSlice';
import settingsReducer from '../pages/settings/settingsSlice';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const myPlacePersistConfig = {
  key: 'myVenues',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const appReducer = combineReducers({
  /* your app’s top-level reducers */
  overview: overviewReducer,
  settings: settingsReducer,
  orginization:orginizationUsersReducer,
  venueOnboardEdit: venueOnboardEditReducer,
  spaceOnboardEdit: spaceOnboardEditReducer,
})

const rootReducer = (state, action) => {
  console.log(action.type)
  if (action.type === 'settings/logoutResetReduxStates') {
    console.log(state, action)
    // for all keys defined in your persistConfig(s)
    // storage.removeItem('persist:root')
    window.localStorage.removeItem('redux-root')
    window.localStorage.removeItem('redux-myVenues')
    window.localStorage.removeItem('redux-product')
    window.localStorage.removeItem('redux-myVenues')

    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export { rootPersistConfig, rootReducer };
