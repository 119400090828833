import PropTypes from 'prop-types';
import { useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Card, CardContent, CardActions, Divider, Button, Typography, Stack, Switch } from '@mui/material';
// redux
import { useDispatch } from '../../../../redux/store';
import { getGoogleCalendarList, disableSpace, openCalendarModal } from '../VenuesSlice';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// components
import Label from '../../../../components/Label';
import Image from '../../../../components/Image';
import Iconify from '../../../../components/Iconify';
// hooks
import useLocales from '../../../../hooks/useLocales';

// ----------------------------------------------------------------------

const spaceTypeLabels = {
  offices: 'Private office',
  'meeting-rooms': 'Meeting room',
  creative: 'Creative space',
  work: 'Shared workspace',
};
// ----------------------------------------------------------------------

SpaceCard.propTypes = {
  space: PropTypes.object,
};

export default function SpaceCard({ space }) {
  const dispatch = useDispatch();
  const { Translate, translate: t } = useLocales();
  const { org_id = '', venue_id = '' } = useParams();
  const { id, org_id: orgId, space_id, name, description, cover_photo: cover, isEnabled, venue_type, space_type: spaceType, currency, price_per_hour: hourlyPrice, price_per_day: dailyPrice } = space;
  // const isUpdateLink = Boolean(space.calendar_id);
  console.log(space, "space");
  const linkTo = !(spaceType === "Meeting_Room") ? `${PATH_DASHBOARD.organisation.root}/${orgId}/venues/${id}/edit` : `${PATH_DASHBOARD.organisation.root}/${org_id}/venues/${venue_id}/spaces/${id}/edit`;

  const [switchEnabled, setSwitchEnabled] = useState(isEnabled);
  const onSubmit = (event) => {
    try {
      setSwitchEnabled(event.target.checked);
      // dispatch(disableSpace(id, +event.target.checked));
    } catch (error) {
      console.error(error);
    }
  };
  const handleOpenModal = () => {
    // dispatch(getGoogleCalendarList(venue_id, id));
    // setTimeout(() => dispatch(openCalendarModal()), 300);
  };

  return (
    <Card>
      <Box sx={{ position: 'relative' }}>
        {1 === 0 && (
          <Label
            variant="filled"
            color="warning"
            sx={{
              top: 16,
              right: 16,
              zIndex: 9,
              fontSize: 8,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            <Translate i18nKey="global.underVerification" />
          </Label>
        )}
        <Image alt={name} src={cover} ratio="4/3" />
      </Box>
      <CardContent sx={{ px: 2, pt: 2, pb: 0 }}>
        <Typography variant="body2" color="text.secondary" noWrap>
          {spaceType?.toUpperCase().replace("_", " ") || venue_type?.toUpperCase().replace("_", " ")}
        </Typography>
        <Typography variant="subtitle1" noWrap gutterBottom>
          {name}
        </Typography>
        {!org_id && <Typography variant="p">
          {description}
        </Typography>}
        <Divider sx={{ width: '100%', mb:2, mt:2 }} />
        {org_id &&
          <Stack direction="row" alignItems="center" sx={{ mt: 2 }} justifyContent="space-between">
            <Stack spacing={0}>
              <Typography variant="caption" fontWeight="500">
                <Translate i18nKey="rooms.hourly" />
              </Typography>
              <Typography variant="body2" fontWeight="500" noWrap>
                {hourlyPrice ? (
                  <>
                    {currency} {hourlyPrice}
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            </Stack>
            <Stack spacing={0}>
              <Typography variant="caption" fontWeight="500">
                <Translate i18nKey="rooms.daily" />
              </Typography>
              <Typography variant="body2" fontWeight="500" noWrap>
                {dailyPrice ? (
                  <>
                    {currency} {dailyPrice}
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            </Stack>
          </Stack>
        }
      </CardContent>
      <CardActions>
        <ActionItem component={RouterLink} to={linkTo} text={t('global.edit')} />
        <Icons alignSelf={"right"} space={space} onClick={() => handleOpenModal()} sx={{ mr: 'auto' }} />
        {/* <Box sx={{ flexGrow: 1 }} />
        {(isEnabled || 1) && <Switch name="isEnabled" label="" size="small" checked={switchEnabled} onChange={onSubmit} />} */}
      </CardActions>
    </Card>
  );
}

const ActionItem = ({ text, callback, sx, ...other }) => (
  <Button variant="text" size="small" sx={{ minWidth: 'auto', fontWeight: '500', ...sx }} onClick={callback} {...other}>
    {text}
  </Button>
);

ActionItem.propTypes = {
  text: PropTypes.string,
  callback: PropTypes.func,
  sx: PropTypes.object,
  space: PropTypes.object,
};

const Icons = ({ status, sx, space, ...other }) => {
  const { translate: t } = useLocales();
  return (
    <Button variant="text" size="small" sx={{ flex:1 , justifyContent:"end",  minWidth: 'auto', fontWeight: '500', fontSize: 20, ...sx }} {...other}>
      {space?.is_cowork && <Iconify icon={'guidance:coworking-space'} size="large" sx={{ mr: 1 }} />}
      {space?.is_meeting_room && <Iconify icon={'mdi:virtual-meeting'} size="large" sx={{ mr: 1 }} />}
      {space?.is_creative_studio && <Iconify icon={'fluent-emoji-high-contrast:studio-microphone'} size="large" sx={{ mr: 1 }} />}
      {space?.is_office_room && <Iconify icon={'guidance:office'} size="large" sx={{ mr: 1 }} />}
    </Button>
  );
};

Icons.propTypes = {
  status: PropTypes.bool,
  unlink: PropTypes.string,
  link: PropTypes.string,
  sx: PropTypes.object,
};
