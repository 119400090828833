import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { IconButton, InputAdornment } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../../components/Iconify';
import InputStyle from '../../../../components/InputStyle';
// hooks
import useLocales from '../../../../hooks/useLocales';

// ----------------------------------------------------------------------

Search.propTypes = {
  isSpaceList: PropTypes.bool,
  updateFilter: PropTypes.func,
  initValue: PropTypes.string,
};

export default function Search({ isSpaceList, updateFilter, initValue }) {

  const [searchQuery, setSearchQuery] = useState(initValue);

  const { translate: t } = useLocales();
  const theme = useTheme();

  const handleClick = (name) => {
    updateFilter(name);
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleClick(searchQuery);
    }
  };

  return (
    <>
    <InputStyle
      size="small"
      stretchStart={240}
      value={searchQuery}
      onChange={(event)=>setSearchQuery(event.target.value)}
      onKeyDown={handleKeyUp}
      placeholder={isSpaceList?t('rooms.placeholder-search'):t('venues.placeholder-search')}
      sx={{mb: 4}}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
          </InputAdornment>
        ),
      }}
    />
    <IconButton onClick={() => handleClick(searchQuery)} type="submit" color="secondary">
      <Iconify
        icon={theme.direction === 'ltr' ? 'bi:arrow-right-circle' : 'bi:arrow-left-circle'}  
        width={24}
        height={24}
      />
    </IconButton>
    </>
  );
}
