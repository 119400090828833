import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
// config
import { HOST_API } from '../utils/config';
// locale
import i18n from '../locales/i18n';

// ----------------------------------------------------------------------



const axiosInstance = axios.create({
  baseURL: HOST_API,
});

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const accessToken = window.localStorage.getItem('accessToken');
    // const org_id = window.localStorage.getItem('org_id');
    const params = { ...(config.params && config.params || {}) };
    params.lang = i18n.language;

    const newConfig = { ...config, params };
    if (accessToken) {
      newConfig.headers.Authorization = `Bearer ${accessToken}`
    }
    // Replace :org_id in the URL with the user's organization ID
    // if (org_id && newConfig.url.includes(":org_id")) {
    //   console.log(newConfig.url, newConfig.url.replace(':org_id', org_id));
    //   newConfig.url = newConfig.url.replace(':org_id', org_id);
    // }
    return newConfig;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  });

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   // (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
//   (error) => Promise.reject((error.response && error.response.statusText) || 'Something went wrong')
// );

export default axios;
